<template>
  <v-container id="dashboard" fluid tag="section">
    <v-form ref="form">
      <base-material-card icon="mdi-cart">
        <slot slot="title">{{order.name}}</slot>
        <slot slot="rightButtons" v-if="order.id">
          <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
        </slot>
          <div v-if="showEditToggle">

          <text-field label="Name" v-model="editOrder.name" :rules="[utils.requiredRule]">
            <slot slot="tooltip">The name of the person ordering the tickets</slot> 
          </text-field>

          <div class="d-flex">
            <v-select label="Status" menu-props="auto" v-model="editOrder.status" :items="statuses"/>
          </div>

          <text-field label="Phone" v-model="editOrder.phone">
            <slot slot="tooltip">The phone number of the person ordering the tickets</slot> 
          </text-field>

          <text-field label="Address 1" v-model="editOrder.address1">
            <slot slot="tooltip">The address of the person ordering the tickets.  This is only collected if they order a product for delivery.</slot> 
          </text-field>

          <text-field label="Address 2" v-model="editOrder.address2">
          </text-field>

          <text-field label="Town" v-model="editOrder.town">
          </text-field>

          <text-field label="Postcode" v-model="editOrder.postcode">
          </text-field>

          <text-field label="Date Of Birth" v-model="editOrder.dateOfBirth" :rules="[utils.dateOfBirthRule]">
          </text-field>

          <text-field label="Email" v-model="editOrder.email" :rules="[utils.emailRule]">
            <slot slot="tooltip">The email of the person ordering the tickets</slot> 
          </text-field>

          <text-field label="Discount Amount" prefix="£" v-model="editOrder.discountAmount" :rules="[utils.priceRule]">
            <slot slot="tooltip">The amount of any discount.</slot> 
          </text-field>

          <text-field label="Donation Amount" prefix="£" v-model="editOrder.donationAmount" :rules="[utils.priceRule]">
            <slot slot="tooltip">The amount of any donation.</slot> 
          </text-field>

          <div class="d-flex">
            <v-switch v-model="editOrder.cash" label="Cash Sale?"></v-switch>
            <div class="pt-5 ml-1" style="valign:middle">
              <tooltip top>Was this order paid for with cash?</tooltip>
            </div>
          </div>

          <div class="d-flex">
            <v-switch v-model="editOrder.marketing" label="Marketing Opt-in?"></v-switch>
            <div class="pt-5 ml-1" style="valign:middle">
              <tooltip top>Did this customer opt-in to receive marketing?  If they did, and you <br/>have connected Mailchimp, then they will be tagged
                as "marketing opt-in"
              </tooltip>
            </div>
          </div>

            <v-row class="mt-5">
              <v-col cols="auto">
                <v-btn color="success" @click="updateOrder">Save</v-btn>
                <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
              </v-col>
              <v-spacer/>
              <v-col cols="auto">
                <v-btn class="mr-0" color="error" @click="deleteOrder">Delete</v-btn>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="4">Order Number</v-col>
              <v-col cols="8">{{order.orderNumber}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Status</v-col>
              <v-col cols="8">{{order.status}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Name</v-col>
              <v-col cols="8">{{order.name}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Email</v-col>
              <v-col cols="8">{{order.email}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Phone</v-col>
              <v-col cols="8">{{order.phone}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Address 1</v-col>
              <v-col cols="8">{{order.address1}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Address 2</v-col>
              <v-col cols="8">{{order.address2}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Town/City</v-col>
              <v-col cols="8">{{order.town}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Postcode</v-col>
              <v-col cols="8">{{order.postcode}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Date of Birth</v-col>
              <v-col cols="8">{{order.dateOfBirth}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Order Date</v-col>
              <v-col cols="8">{{utils.dateToShortString(order.created)}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Total Cost</v-col>
              <v-col cols="8">{{utils.priceDisplay(order.totalCost)}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Paid Amount</v-col>
              <v-col cols="8">{{utils.priceDisplay(order.paidAmount)}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Donation Amount</v-col>
              <v-col cols="8">{{utils.priceDisplay(order.donationAmount)}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Discount Amount</v-col>
              <v-col cols="8">{{utils.priceDisplay(order.discountAmount)}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Cash Sale?</v-col>
              <v-col cols="8">{{order.cash}}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Marketing opt-in?</v-col>
              <v-col cols="8">{{order.marketing}}</v-col>
            </v-row>
            <v-row v-if="order.paymentIntent">
              <v-col cols="4">View in Stripe</v-col>
              <v-col cols="8"><a target="_blank" :href="makeStripeUrl()">{{order.paymentIntent}}</a></v-col>
            </v-row>
            <v-row v-if="order.receiptUrl">
              <v-col cols="4">View Receipt</v-col>
              <v-col cols="8"><a target="_blank" :href="order.receiptUrl">receipt</a></v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col cols="auto">
                <v-btn color="success" :disabled="disableEmailButton" @click="emailOrder">Email order to {{order.email}}</v-btn>
              </v-col>
            </v-row>
          </div>
      </base-material-card>
    </v-form>
    
    <v-form ref="orderItem">
      <base-material-card icon="mdi-cart-check">
        <slot slot="title">Order Items</slot>
        <slot slot="rightButtons">
          <toggle-button text="Add a new Order Item" openIcon="mdi-plus" @toggle="showCreateOrderItemToggle = !showCreateOrderItemToggle" :showing="showCreateOrderItemToggle"/>
        </slot>
        <div v-if="showCreateOrderItemToggle">
          <v-tabs>
            <v-tab @click="ticketTab">Tickets</v-tab>
            <v-tab @click="productTab">Products</v-tab>
            <v-tab-item>
              <div v-if="events && events.length > 0">
                <v-select label="Event" menu-props="auto" item-value="id" item-text="name" v-on:change="reloadEvent" v-model="orderItem.eventId" :items="events">
                </v-select>
              </div>

              <div v-if="eventTimes && eventTimes.length > 0 || reloadEventFlag">
                <v-select label="Event Time" menu-props="auto" item-value="id" v-model="orderItem.eventTimeId" :items="eventTimes">
                  <template v-slot:item="{ item }">{{utils.dateToShortString(item.start)}}</template>
                  <template v-slot:selection="{ item }">{{utils.dateToShortString(item.start)}}</template>
                </v-select>
              </div>

              <div v-if="ticketTypes && ticketTypes.length > 0 || reloadEventFlag">
                <v-select label="Ticket Type" menu-props="auto" item-value="id" item-text="name" v-model="orderItem.ticketTypeId" :items="ticketTypes">
                </v-select>
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-select label="Product" menu-props="auto" item-value="id" item-text="name" v-model="orderItem.productId" :items="products">
              </v-select>
            </v-tab-item>
          </v-tabs>

        <text-field label="Quantity" v-model="orderItem.quantity" :rules="[utils.integerRule]">
          <slot slot="tooltip">How many tickets or products purchased.</slot> 
        </text-field>

          <v-btn color="success" class="mb-10" @click="createOrderItem">Create Order Item</v-btn>

        </div>
        <v-row class="mx-n1 underlinedRow">
          <v-col cols="6">Description</v-col>
          <v-col cols="3">Quantity</v-col>
          <v-col cols="3">Value</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(orderItem, i) in orderItems" :key="i" @click="selectOrderItem(orderItem)">
              <v-col v-if="orderItem.ticketTypeId" cols="6">{{orderItem.eventName}} / {{orderItem.ticketTypeName}} / {{utils.dateToShortString(orderItem.eventTimeStart)}}</v-col>
              <v-col v-else cols="6">{{orderItem.productName}}<span v-if="orderItem.productOption"> / {{orderItem.productOption}}</span></v-col>
              <v-col cols="3">{{orderItem.quantity}}</v-col>
              <v-col cols="3">{{utils.priceDisplay(orderItem.value)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </base-material-card>
    </v-form>

    <ticket-list v-if="order.boxOfficeId" :order="order"/>

  </v-container>

</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js';
  import Store from '@/services/Store.js';

  export default {
    name: 'Order',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      TicketList: () => import('./TicketList'),
    },  

    data () {
      return {
        showCreateOrderItemToggle: false,
        boxOffice: {},
        order: {},
        orderItems: {},
        orderItem: {},
        orderName: {},
        statuses: [],
        showCreateOrderToggle: false,
        showEditToggle: false,
        disableEmailButton: false,
        event: {},
        tickets: {},
        ticket: {},
        eventTimes: [],
        events: [],
        products: [],
        reloadEventFlag: false,
        ticketTypes: [],
      }
    },

    created() {
      this.order.id = this.$route.params.id;
      this.load(this.order);
    },
    
    computed: {
      utils: function () {
        return Utils;
      },
    },
    
    methods: {

      ticketTab() {
        this.orderItem.productId = null;
      },

      productTab() {
        this.orderItem.eventTimeId = null;
        this.orderItem.ticketTypeId = null;
      },

      async emailOrder() {
        this.disableEmailButton = true;
        Api.post(this, "Order", "email", this.order);
      },

      async loadOrder() {
        this.order = await Api.post(this, "Order", "read", this.order);
      },

      async load() {
        await this.loadOrder();
        this.boxOffice.id = this.order.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        this.events = await Api.post(this, "Event", "list", this.boxOffice);
        this.products = await Api.post(this, "Product", "list", this.boxOffice);
        this.statuses = await Api.post(this, "Order", "statuses");
        Store.store(this.boxOffice);
        this.listOrderItems();
      },

      async reloadEvent(id) {
        this.reloadEventFlag = true;
        this.ticket.eventTimeId = null;
        this.ticket.ticketTypeId = null;
        this.loadEvent(id);
      },

      async loadEvent(id) {
        this.event.id = id;
        this.event = await Api.post(this, "Event", "read", this.event);
        this.eventTimes = await Api.post(this, "EventTime", "list", this.event);
        this.ticketTypes = await Api.post(this, "TicketType", "list", this.event);
      },

      async listOrderItems() {
        this.orderItems = await Api.post(this, "OrderItem", "list", this.order);
      },

      async updateOrder() {
        if (! await Utils.validate(this.$refs.form) ) {
          return;
        }
        this.order = await Api.post(this, "Order", "update", this.editOrder);
        this.load();
        this.hideEdit();
      },

      async createOrderItem() {
        if (! await Utils.validate(this.$refs.orderItem) ) {
          return;
        }
        this.orderItem.boxOfficeId = this.order.boxOfficeId;
        this.orderItem.orderId = this.order.id;
        this.orderItem = await Api.post(this, "OrderItem", "create", this.orderItem);
        window.location.reload();
      },

      async deleteOrder() {
        await Api.post(this, "Order", "delete", this.order);
        this.$router.push("/Dashboard/AllSearch/" + this.order.boxOfficeId);
      },

      showEdit(show) {
        if (show) {
          this.editOrder = Utils.jsonCopy(this.order);
        }
        this.showEditToggle = show;
      },

      hideEdit() {
        this.showEditToggle = false;
      },

      makeStripeUrl() {
        return "https://dashboard.stripe.com/payments/" + this.order.paymentIntent;
      },

      selectItem(ticket) {
        this.$router.push("/Dashboard/Ticket/" + ticket.id)
      },

      selectOrderItem(orderItem) {
        this.$router.push("/Dashboard/OrderItem/" + orderItem.id)
      },

    }
  }
</script>
